import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Offer from './Components/Offer/Offer';
import LoginForm from './Components/LoginForm/LoginForm';
import { BrowserRouter, Routes, Route} from "react-router-dom";

function App () {
    return (
        <div className='App'>
            <Navbar />
            <div className='content'>
                <Routes>
                    <Route exact path = '/' element = {<Home />} />
                    <Route path = '/loginform' element = {<LoginForm />} />
                    <Route path = '/offer' element = {<Offer />} />
                </Routes>
            </div>
        </div>
    )
}

export default App