import React from "react";
import './Home.css';
import backgroundImg from '../../assets/img/background.jpg';

const Home = () => {
    return (
        <div className = 'home' >
            <div className='text'>
                <h1>NEVER STOP EXPLORING THE WORLD</h1>
                <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                <button>Explore</button>
            </div>
            
        </div>
    )
}

export default Home;