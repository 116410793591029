const NavbarItems = [
    {
        title: 'Strona główna',
        url: '/',
        id: 1
    },
    {
        title: 'Firma',
        id: 2, 
        url: "#"
    },
    {
        title: "Oferta",
        url: "offer",
        id: 3
    },
    {
        title: "Kontakt",
        url: "#",
        id: 4
    }
]

export default NavbarItems;