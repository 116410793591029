import React from "react"

const Offer = () => {
    return (
        <div>
            <h3>Offer</h3>
        </div>
    );
}

export default Offer