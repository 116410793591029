import React from "react"
import './Navbar.css';
import NavbarItems from "./NavbarItems";
import { Link } from 'react-router-dom';

const NavLinks = () => {
    const navbarItems = NavbarItems.map(items => <li key = {items.id}><a href = {items.url}>{items.title}</a></li>);

    return (
        <nav className="navbar">
            <h1>Test</h1>
            <ul>
                {navbarItems}
            </ul>
            <Link to='/loginform'>
                <button className = "navbar-button">Zaloguj się</button>
            </Link>
            
        </nav>
    );
}

class Navbar extends React.Component {
    render () {
        return (
            <NavLinks />
        )
    }
}

export default Navbar