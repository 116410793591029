import React from "react";
import "./LoginForm.css";

const LoginForm = () => {
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<div className="login-content">
			<div className="loginform">
				<h2>Zaloguj się</h2>
				<form onSubmit={handleSubmit}>
					<input type="text" required placeholder="Nazwa użytkownika" />
					<input type="text" required placeholder="Hasło użytkownika" />
					<input type="submit" value="Zaloguj się" />
				</form>
			</div>
		</div>
	);
};

export default LoginForm;
